import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import config from '../../config';

// Favicons
import AppleTouchIconPng from '../../assets/favicons/apple-touch-icon.png';
import Favicon16x16Png from '../../assets/favicons/favicon-16x16.png';
import Favicon32x32Png from '../../assets/favicons/favicon-32x32.png';
import FaviconIco from '../../assets/favicons/favicon.ico';
import SafariPinnedTabSvg from '../../assets/favicons/safari-pinned-tab.svg';
import OpenGraphImage from '../../assets/img/social-media/mein-mslife_og.jpg';

/**
 * Function:
 * Append a set suffix to a non suffixed value.
 *
 * @param value
 * @returns {string}
 */
export const appendSeoTitleSuffix = value => {
	return `${value} ${config.seo.titleSuffix}`;
};

/**
 * Render component.
 */
const Meta = props => {
	const {title, description, opengraphSitename, keywords} = props;
	return (
		<Helmet encodeSpecialCharacters={false} defaultTitle={config.seo.title}>
			<html lang="de" />
			<title>{title || appendSeoTitleSuffix(config.seo.description)}</title>
			<meta name="description" content={description || config.seo.description} />

			<link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIconPng} />
			<link rel="icon" type="image/png" sizes="32x32" href={Favicon32x32Png} />
			<link rel="icon" type="image/png" sizes="16x16" href={Favicon16x16Png} />
			<link rel="mask-icon" href={SafariPinnedTabSvg} color="#ff834a" />
			<link rel="shortcut icon" href={FaviconIco} />
			<meta name="msapplication-TileColor" content="#ffffff" />
			<meta name="theme-color" content="#ffffff" />
			<meta name="keywords" content={keywords} />

			<meta property="og:site_name" content={opengraphSitename || config.seo.opengraphSitename} />
			<meta property="og:title" content={title || config.seo.opengraphTitle} />
			<meta property="og:description" content={description || config.seo.opengraphDescription} />
			<meta property="og:image" content={OpenGraphImage} />
			<meta property="og:locale" content="de_DE" />
		</Helmet>
	);
};

Meta.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	opengraphSitename: PropTypes.string,
	keywords: PropTypes.string
};

Meta.defaultProps = {
	title: 'Mein MSlife',
	description: 'Individuelle Begleitung für ein selbstbestimmtes Leben mit MS',
	opengraphSitename: 'Mein MSlife',
	keywords: 'mslife'
};

/** @component */
export default Meta;
